<template>
  <v-row
    id="login"
    class="layer primary"
    align="center"
    justify="center"
    no-gutters
  >

    <alerts absolute/>

    <v-sheet
      color="transparent"
      class="ma-4 pa-4"
      width="100%"
      max-width="400"
    >

      <logo
        class="mb-4"
        height="100"
        color="dark"
        show-version
      />

      <v-card class="pa-4 mb-6">

        <v-alert
          v-html="$t('recoveryPass.info')"
          class="body-2"
          color="dark"
          outlined
        />

        <v-form
          ref="form"
          @submit.prevent="onSubmit"
        >

          <text-field
            autocomplete="username"
            background-color="white"
            v-model="email"
            :label="$t('inputs.email.label')"
            :rules="rules"
            fluid
          />

          <btn
            type="submit"
            class="caption mt-2 mb-3"
            color="black"
            :loading="loading"
            block dark
          >
            {{ $t('btn.send') }}
          </btn>


        </v-form>
      </v-card>

      <router-link class="caption black--text" :to="$lp('/login')">
        {{ $t('btn.login') }}
      </router-link>

    </v-sheet>
  </v-row>
</template>

<script>
import Alerts from '@/components/Alerts';
import TextField from '@/components/form/TextField';
import { required, isEmail } from '@/utils/rules';
import { mapActions } from 'vuex';

export default {
  name: 'RecoveryPass',
  head: vm => ({ title: vm.$i18n.t('recoveryPass.title') }),
  auth: 'guess',
  components: { TextField, Alerts },
  data: () => ({
    loading: false,
    email: ''
  }),
  computed: {
    rules() {
      return [
        required( this.$t('inputs.email.rules.required')),
        isEmail( this.$t('inputs.email.rules.isEmail'))
      ];
    }
  },
  methods: {
    ...mapActions( 'auth', [ 'recoveryPass' ]),
    ...mapActions( 'app', [ 'alert' ]),
    validate() {
      return this.$refs.form && this.$refs.form.validate();
    },
    resetValidation() {
      return this.$refs.form && this.$refs.form.resetValidation();
    },
    reset() {
      return this.$refs.form && this.$refs.form.reset();
    },
    onSubmit() {
      if ( this.loading ) return;
      if ( this.validate()) {
        const { email } = this;
        this.loading = true;
        this.recoveryPass({ email }).then(() => {
          this.$router.replace( this.$lp('/reset-pass?email=' + email ));
          this.alert({
            type: 'success',
            message: this.$t('alerts.recoveryPass')
          });
        }).catch( err => {
          this.alert({
            type: 'error',
            message: err.message
          });
        }).finally(() => {
          this.loading = false;
        });
      }
    }
  }
}
</script>
